<script lang="ts">
  import moment from "moment";
  import "moment/locale/da";
  import { onMount } from "svelte";

  export let matchDays: {
    date: string;
    matches: {
      away: string;
      channelsArray: string[];
      gameStart: string;
      home: string;
      league: string;
    }[];
  }[] = [];

  // const url = "http://localhost:3001/api";
  const url = "https://fit-api.azurewebsites.net/api";
  onMount(async () => {
    try {
      const r = await fetch(url);
      const json = await r.json();
      matchDays = json; 
    } catch (error) {
      alert("Intet virker...");
    }
  });
</script>

<main>
  <div class="navbar">
    <div>FIT</div>
  </div>
  <div class="scroll">
    <ul class="listBorder">
      {#each matchDays as matchDay, i}
        <li class="title lowercase">
          { moment(matchDay.date).format("dddd DD. MMMM YYYY")}
        </li>
        <div>
          {#each matchDay.matches as game, i2}
            <div class="item">
              <div class="container justify">
                <div>
                  {game.gameStart || ""}
                </div>
                <div>{(game.channelsArray || []).join(", ") || ""}</div>
              </div>

              <div class="game">{game.home} - {game.away}</div>
              <div class="container">{game.league || ""}</div>
            </div>
          {/each}
        </div>
      {/each}
    </ul>
  </div>
</main>

<style>
  .game {
    font-weight: bold;
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .item {
    padding: 1rem;
    border-bottom: 1px solid #e0e0e0;
  }
  .container {
    display: flex;
  }
  .lowercase {
    text-transform: capitalize;
  }
  .justify {
    justify-content: space-between;
  }
  .listBorder {
    border: 1px solid #ccc !important;
    list-style-type: none;
    padding: 0;
    /* padding-top: 50px; */
    overflow: scroll;
    margin: 0;
  }
  .listBorder li {
    padding: 8px 16px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  .title {
    font-size: 1.1rem;
    text-align: center !important;
    background-color: #1565c0;
    color: #fff;
  }
  .navbar {
    background-color: #fff;
    /* position: fixed; */
    top: 0;
    width: 100%;
    font-size: 50px;
    height: 70px;
  }
  main {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .scroll {
    height: 100%;
    overflow: scroll;
  }
</style>
